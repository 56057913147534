<template>
  <div id="salary" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-1">REPORTES NOM-035</v-row>
      <v-row no-gutters justify="start" class="mt-3 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="enterprise">Razón Social</label>
          <select
            name="enterprise"
            id="enterprise"
            v-model="enterprise"
            @change="obtenerEncuesta()"
          >
            <option selected disabled value="0">Selecciona Empresa</option>
            <option
              v-for="enterprise in enterprises"
              :key="enterprise.id"
              v-bind:value="enterprise.id"
            >
              {{ enterprise.razonSocial }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="area">Área</label>
          <select
            name="area"
            id="area"
            v-model="area"
            @change="actualizarCantidadEmpleados()"
          >
            <option selected value="0">Sin especificar</option>
            <option v-for="area in areas" :key="area.id" v-bind:value="area.id">
              {{ area.nombreArea }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="sucursal">Centro de trabajo</label>
          <select
            name="sucursal"
            id="sucursal"
            v-model="sucursal"
            @change="actualizarCantidadEmpleados()"
          >
            <option selected value="0">Sin especificar</option>
            <option
              v-for="suc in sucursales"
              :key="suc.id"
              v-bind:value="suc.id"
            >
              {{ suc.nombreSucursal }}
            </option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters justify="end" class="mt-10 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="empleados">Número de empleados</label>
          <input
            type="text"
            name="empleados"
            id="empleados"
            placeholder="Obligatorio"
            class="inputs"
            autocomplete="false"
            v-model="empleados"
            required
            readonly
          />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="encuestas">Encuestas en proceso</label>
          <input
            type="text"
            name="encuestas"
            id="encuestas"
            placeholder="Obligatorio"
            class="inputs"
            autocomplete="false"
            v-model="encuestas"
            required
            readonly
          />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4" class="mt-n5">
          <label for="completadas">Encuestas completadas</label>
          <input
            type="text"
            name="completadas"
            id="completadas"
            placeholder="Obligatorio"
            class="inputs"
            autocomplete="false"
            v-model="completadas"
            required
            readonly
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="start" class="mt-10 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="guia">Resultados guía</label>
          <select
            name="guia"
            id="guia"
            v-model="guia"
            @change="obtenerCategoria()"
            v-if="empleadosTotales == 0 || empleadosTotales < 16"
          >
            <option selected disabled value="0">Sin opciones</option>
          </select>
          <select
            name="guia"
            id="guia"
            v-model="guia"
            disabled="false"
            @change="obtenerCategoria()"
            v-if="empleadosTotales > 15 && empleadosTotales < 21"
          >
            <option selected disabled value="0">Seleccione</option>
            <option value="2">II</option>
          </select>
          <select
            name="guia"
            id="guia"
            v-model="guia"
            @change="obtenerCategoria()"
            v-if="empleadosTotales > 20"
          >
            <option selected disabled value="0">Seleccione</option>
            <option value="2">II</option>
            <option value="3">III</option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="categoria">Categoría</label>
          <select
            name="categoria"
            id="categoria"
            v-model="categoria"
            disabled=""
            @change="obtenerDominio()"
          >
            <option selected disabled value="0">Sin especificar</option>
            <option v-for="cat in categorias" :key="cat.id" v-bind:value="cat">
              {{ cat.nombreCategoria }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="dominio">Dominio</label>
          <select
            name="dominio"
            id="dominio"
            v-model="dominio"
            disabled=""
            @change="obtenerDimensiones()"
          >
            <option selected disabled value="0">Seleccione Dominio</option>
            <option v-for="dom in dominios" :key="dom.id" v-bind:value="dom">
              {{ dom.nombreDominio }}
            </option>
          </select>
        </v-col>
      </v-row>
      <v-row v-if="normal">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-12 tablaReportes">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>Categoría</th>
                  <th>Dominio</th>
                  <th>Dimensión</th>
                  <th>Tot. Dominio</th>
                  <th>Tot. Categoria</th>
                  <th>Nivel riesgo por categoria</th>
                  <th>Nivel Por Dominio</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cat, c) in categoriasSelect"
                  :key="c"
                  class="text-center"
                >
                  <td class="text-center">
                    {{ cat.nombreCategoria }}
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(dom, d) in dominiosSelect"
                      :key="d"
                      class="text-center"
                    >
                      {{
                        filtrar(dom, cat)
                      }}
                    </tr>
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(dim, di) in dimensionesSelect"
                      :key="di"
                      class="text-center"
                    >
                      {{
                        filtrarDimensiones(dim, cat)
                      }}
                    </tr>
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(dom, d) in dominiosSelect"
                      :key="d"
                      class="text-center align-center"
                    >
                      {{
                        puntajeDominio
                      }}
                    </tr>
                  </td>
                  <td class="text-center">{{ puntajeCategoria }}</td>
                  <td :class="color2" style="color: white !important">
                    {{ calificacionCategoria }}
                    <br />
                    <span
                      style="font-size: 0.9em; color: black"
                      v-if="calificacionCategoria === 'Muy alto'"
                      >Se requiere realizar el análisis de cada categoría y
                      dominio para establecer las acciones de intervención
                      apropiadas, mediante un Programa de intervención que
                      deberá incluir evaluaciones específicas1, y contemplar
                      campañas de sensibilización, revisar la política de
                      prevención de riesgos psicosociales y programas para la
                      prevención de los factores de riesgo psicosocial, la
                      promoción de un entorno organizacional favorable y la
                      prevención de la violencia laboral, así como reforzar su
                      aplicación y difusión.</span
                    >
                    <span
                      style="font-size: 0.9em; color: black"
                      v-if="calificacionCategoria === 'Alto'"
                      >Se requiere realizar un análisis de cada categoría y
                      dominio, de manera que se puedan determinar las acciones
                      de intervención apropiadas a través de un Programa de
                      intervención, que podrá incluir una evaluación específica1
                      y deberá incluir una campaña de sensibilización, revisar
                      la política de prevención de riesgos psicosociales y
                      programas para la prevención de los factores de riesgo
                      psicosocial, la promoción de un entorno organizacional
                      favorable y la prevención de la violencia laboral, así
                      como reforzar su aplicación y difusión.</span
                    >
                    <span
                      style="font-size: 0.9em; color: black"
                      v-if="calificacionCategoria === 'Medio'"
                      >Se requiere revisar la política de prevención de riesgos
                      psicosociales y programas para la prevención de los
                      factores de riesgo psicosocial, la promoción de un entorno
                      organizacional favorable y la prevención de la violencia
                      laboral, así como reforzar su aplicación y difusión,
                      mediante un Programa de intervención.</span
                    >
                    <span
                      style="font-size: 0.9em; color: black"
                      v-if="calificacionCategoria === 'Bajo'"
                      >Es necesario una mayor difusión de la política de
                      prevención de riesgos psicosociales y programas para: la
                      prevención de los factores de riesgo psicosocial, la
                      promoción de un entorno organizacional favorable y la
                      prevención de la violencia laboral.</span
                    >
                    <span
                      style="font-size: 0.9em; color: black"
                      v-if="calificacionCategoria === 'Nulo o despreciable'"
                      >El riesgo resulta despreciable por lo que no se requiere
                      medidas adicionales.</span
                    >
                  </td>
                  <td
                    class="text-center"
                    :class="color"
                    style="color: white !important"
                  >
                    <tr
                      v-for="(dom, d) in dominiosSelect"
                      :key="d"
                      :class="color"
                      style="color: white !important"
                    >
                      {{
                        calificacionDominio
                      }}
                      <br />
                      <span
                        style="font-size: 0.9em; color: black"
                        v-if="calificacionDominio === 'Muy alto'"
                        >Se requiere realizar el análisis de cada categoría y
                        dominio para establecer las acciones de intervención
                        apropiadas, mediante un Programa de intervención que
                        deberá incluir evaluaciones específicas1, y contemplar
                        campañas de sensibilización, revisar la política de
                        prevención de riesgos psicosociales y programas para la
                        prevención de los factores de riesgo psicosocial, la
                        promoción de un entorno organizacional favorable y la
                        prevención de la violencia laboral, así como reforzar su
                        aplicación y difusión.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-if="calificacionDominio === 'Alto'"
                        >Se requiere realizar un análisis de cada categoría y
                        dominio, de manera que se puedan determinar las acciones
                        de intervención apropiadas a través de un Programa de
                        intervención, que podrá incluir una evaluación
                        específica1 y deberá incluir una campaña de
                        sensibilización, revisar la política de prevención de
                        riesgos psicosociales y programas para la prevención de
                        los factores de riesgo psicosocial, la promoción de un
                        entorno organizacional favorable y la prevención de la
                        violencia laboral, así como reforzar su aplicación y
                        difusión.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-if="calificacionDominio === 'Medio'"
                        >Se requiere revisar la política de prevención de
                        riesgos psicosociales y programas para la prevención de
                        los factores de riesgo psicosocial, la promoción de un
                        entorno organizacional favorable y la prevención de la
                        violencia laboral, así como reforzar su aplicación y
                        difusión, mediante un Programa de intervención.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-if="calificacionDominio === 'Bajo'"
                        >Es necesario una mayor difusión de la política de
                        prevención de riesgos psicosociales y programas para: la
                        prevención de los factores de riesgo psicosocial, la
                        promoción de un entorno organizacional favorable y la
                        prevención de la violencia laboral.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-if="calificacionDominio === 'Nulo o despreciable'"
                        >El riesgo resulta despreciable por lo que no se
                        requiere medidas adicionales.</span
                      >
                    </tr>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="datosCompletos">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-12 tablaReportesCompletos">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>Categoría</th>
                  <th>Dominio</th>
                  <th>Dimensión</th>
                  <th>Tot. Dominio</th>
                  <th>Tot. Categoria</th>
                  <th>Nivel riesgo por categoria</th>
                  <th>Nivel Por Dominio</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cat, c) in guia2.categorias"
                  :key="c"
                  class="text-center"
                >
                  <td class="text-center">
                    {{ cat.nombreCategoria }}
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(dom, d) in guia2.dominios"
                      :key="d"
                      class="text-center"
                      v-show="filtrar(dom, cat)"
                    >
                      {{
                        filtrar(dom, cat)
                      }}
                    </tr>
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(dim, di) in guia2.dimensiones"
                      :key="di"
                      class="text-center"
                      v-show="filtrarDimensiones(dim, cat)"
                      style="background-color: none !important"
                    >
                      {{
                        filtrarDimensiones(dim, cat)
                      }}
                    </tr>
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(punDom, p) in guia2.puntajesDominio"
                      :key="p"
                      class="text-center align-center"
                      v-show="punDominio(punDom, cat)"
                    >
                      {{
                        punDominio(punDom, cat)
                      }}
                    </tr>
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(punCat, pc) in guia2.puntajesCategoria"
                      :key="pc"
                      class="text-center align-center"
                      v-show="punCategoria(punCat, cat)"
                    >
                      {{
                        punCategoria(punCat, cat)
                      }}
                    </tr>
                  </td>
                  <td :class="calificacionCategoriaColor">
                    <tr
                      v-for="(cal, c) in guia2.calificacionesCategoria"
                      :key="c"
                      v-show="calCategoria(cal, cat)"
                      :class="
                        calCategoria(cal, cat) === 'Muy Alto'
                          ? 'red darken-1'
                          : calCategoria(cal, cat) === 'Alto'
                          ? 'orange darken-1'
                          : calCategoria(cal, cat) === 'Medio'
                          ? 'yellow lighten-3'
                          : calCategoria(cal, cat) === 'Bajo'
                          ? 'green accent-3'
                          : calCategoria(cal, cat) === 'Nulo o despreciable'
                          ? 'blue'
                          : 'white'
                      "
                      style="color: white !important"
                    >
                      {{
                        calCategoria(cal, cat)
                      }}
                      <br />
                      <span
                        style="font-size: 0.9em; color: black !important"
                        v-show="calCategoria(cal, cat) === 'Muy alto'"
                        >Se requiere realizar el análisis de cada categoría y
                        dominio para establecer las acciones de intervención
                        apropiadas, mediante un Programa de intervención que
                        deberá incluir evaluaciones específicas1, y contemplar
                        campañas de sensibilización, revisar la política de
                        prevención de riesgos psicosociales y programas para la
                        prevención de los factores de riesgo psicosocial, la
                        promoción de un entorno organizacional favorable y la
                        prevención de la violencia laboral, así como reforzar su
                        aplicación y difusión.</span
                      >
                      <span
                        style="font-size: 0.9em"
                        v-show="calCategoria(cal, cat) === 'Alto'"
                        >Se requiere realizar un análisis de cada categoría y
                        dominio, de manera que se puedan determinar las acciones
                        de intervención apropiadas a través de un Programa de
                        intervención, que podrá incluir una evaluación
                        específica1 y deberá incluir una campaña de
                        sensibilización, revisar la política de prevención de
                        riesgos psicosociales y programas para la prevención de
                        los factores de riesgo psicosocial, la promoción de un
                        entorno organizacional favorable y la prevención de la
                        violencia laboral, así como reforzar su aplicación y
                        difusión.</span
                      >
                      <span
                        style="font-size: 0.9em"
                        v-show="calCategoria(cal, cat) === 'Medio'"
                        >Se requiere revisar la política de prevención de
                        riesgos psicosociales y programas para la prevención de
                        los factores de riesgo psicosocial, la promoción de un
                        entorno organizacional favorable y la prevención de la
                        violencia laboral, así como reforzar su aplicación y
                        difusión, mediante un Programa de intervención.</span
                      >
                      <span
                        style="font-size: 0.9em"
                        v-show="calCategoria(cal, cat) === 'Bajo'"
                        >Es necesario una mayor difusión de la política de
                        prevención de riesgos psicosociales y programas para: la
                        prevención de los factores de riesgo psicosocial, la
                        promoción de un entorno organizacional favorable y la
                        prevención de la violencia laboral.</span
                      >
                      <span
                        style="font-size: 0.9em"
                        v-show="
                          calCategoria(cal, cat) === 'Nulo o despreciable'
                        "
                        >El riesgo resulta despreciable por lo que no se
                        requiere medidas adicionales.</span
                      >
                    </tr>
                  </td>
                  <td class="text-center">
                    <tr
                      v-for="(dom, d) in guia2.calificacionesDominio"
                      :key="d"
                      v-show="calDominio(dom, cat)"
                      :class="
                        calDominio(dom, cat) === 'Muy Alto'
                          ? 'red darken-1'
                          : calDominio(dom, cat) === 'Alto'
                          ? 'orange darken-1'
                          : calDominio(dom, cat) === 'Medio'
                          ? 'yellow lighten-3'
                          : calDominio(dom, cat) === 'Bajo'
                          ? 'green accent-3'
                          : calDominio(dom, cat) === 'Nulo o despreciable'
                          ? 'blue'
                          : 'white'
                      "
                      style="color: black !important"
                    >
                      {{
                        calDominio(dom, cat)
                      }}
                      <br />
                      <span
                        style="font-size: 0.9em; color: black"
                        v-show="calDominio(dom, cat) === 'Muy alto'"
                        >Se requiere realizar el análisis de cada categoría y
                        dominio para establecer las acciones de intervención
                        apropiadas, mediante un Programa de intervención que
                        deberá incluir evaluaciones específicas1, y contemplar
                        campañas de sensibilización, revisar la política de
                        prevención de riesgos psicosociales y programas para la
                        prevención de los factores de riesgo psicosocial, la
                        promoción de un entorno organizacional favorable y la
                        prevención de la violencia laboral, así como reforzar su
                        aplicación y difusión.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-show="calDominio(dom, cat) === 'Alto'"
                        >Se requiere realizar un análisis de cada categoría y
                        dominio, de manera que se puedan determinar las acciones
                        de intervención apropiadas a través de un Programa de
                        intervención, que podrá incluir una evaluación
                        específica1 y deberá incluir una campaña de
                        sensibilización, revisar la política de prevención de
                        riesgos psicosociales y programas para la prevención de
                        los factores de riesgo psicosocial, la promoción de un
                        entorno organizacional favorable y la prevención de la
                        violencia laboral, así como reforzar su aplicación y
                        difusión.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-show="calDominio(dom, cat) === 'Medio'"
                        >Se requiere revisar la política de prevención de
                        riesgos psicosociales y programas para la prevención de
                        los factores de riesgo psicosocial, la promoción de un
                        entorno organizacional favorable y la prevención de la
                        violencia laboral, así como reforzar su aplicación y
                        difusión, mediante un Programa de intervención.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-show="calDominio(dom, cat) === 'Bajo'"
                        >Es necesario una mayor difusión de la política de
                        prevención de riesgos psicosociales y programas para: la
                        prevención de los factores de riesgo psicosocial, la
                        promoción de un entorno organizacional favorable y la
                        prevención de la violencia laboral.</span
                      >
                      <span
                        style="font-size: 0.9em; color: black"
                        v-show="calDominio(dom, cat) === 'Nulo o despreciable'"
                        >El riesgo resulta despreciable por lo que no se
                        requiere medidas adicionales.</span
                      >
                    </tr>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="sinDatos" justify="center">
        <v-col sm="5" md="5" lg="5" xl="5" align-self="center">
          <v-card class="ml-2 tablaReportes letraTabla" width="500">
            <table width="100%">
              <thead>
                Riesgo General
              </thead>
              <tbody>
                <tr>
                  {{
                    puntajeFinal
                  }}
                </tr>
                <tr :class="color3" style="color: white !important">
                  {{
                    calificacionFinal
                  }}
                  <br />
                  <span
                    style="font-size: 0.8em; color: black"
                    v-if="calificacionFinal == 'Muy alto'"
                    >Se requiere realizar el análisis de cada categoría y
                    dominio para establecer las acciones de intervención
                    apropiadas, mediante un Programa de intervención que deberá
                    incluir evaluaciones específicas1, y contemplar campañas de
                    sensibilización, revisar la política de prevención de
                    riesgos psicosociales y programas para la prevención de los
                    factores de riesgo psicosocial, la promoción de un entorno
                    organizacional favorable y la prevención de la violencia
                    laboral, así como reforzar su aplicación y difusión.</span
                  >
                  <span
                    style="font-size: 0.8em; color: black"
                    v-if="calificacionFinal == 'Alto'"
                    >Se requiere realizar un análisis de cada categoría y
                    dominio, de manera que se puedan determinar las acciones de
                    intervención apropiadas a través de un Programa de
                    intervención, que podrá incluir una evaluación específica1 y
                    deberá incluir una campaña de sensibilización, revisar la
                    política de prevención de riesgos psicosociales y programas
                    para la prevención de los factores de riesgo psicosocial, la
                    promoción de un entorno organizacional favorable y la
                    prevención de la violencia laboral, así como reforzar su
                    aplicación y difusión.</span
                  >
                  <span
                    style="font-size: 0.8em; color: black"
                    v-if="calificacionFinal == 'Medio'"
                    >Se requiere revisar la política de prevención de riesgos
                    psicosociales y programas para la prevención de los factores
                    de riesgo psicosocial, la promoción de un entorno
                    organizacional favorable y la prevención de la violencia
                    laboral, así como reforzar su aplicación y difusión,
                    mediante un Programa de intervención.</span
                  >
                  <span
                    style="font-size: 0.8em; color: black"
                    v-if="calificacionFinal == 'Bajo'"
                    >Es necesario una mayor difusión de la política de
                    prevención de riesgos psicosociales y programas para: la
                    prevención de los factores de riesgo psicosocial, la
                    promoción de un entorno organizacional favorable y la
                    prevención de la violencia laboral.</span
                  >
                  <span
                    style="font-size: 0.8em; color: black"
                    v-if="calificacionFinal == 'Nulo o despreciable'"
                    >El riesgo resulta despreciable por lo que no se requiere
                    medidas adicionales.</span
                  >
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        class="mt-3"
        v-if="normal == false && sinDatos == false"
      >
        <v-col cols="12" lg="3" md="3" sm="3" xs="3">
          <button class="botonAmarillo" @click="validacion()">Consultar</button>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        class="mt-3"
        v-if="normal == true || sinDatos == true"
      >
        <v-col cols="12" lg="3" md="3" sm="3" xs="3">
          <button class="botonAmarillo" @click="limpiar()">
            Nueva consulta
          </button>
        </v-col>
      </v-row>
    </v-container>
    <!--Error-->
    <v-dialog v-model="alert" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <ul v-if="errors">
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="alert = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      dialog: false,
      expiration: false,
      selectedItem: null,
      empleados: 0,
      encuestas: 0,
      guia: 0,
      categoria: 0,
      categorias: [],
      categoriasSelect: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      areas: [],
      area: 0,
      sucursales: [],
      sucursal: 0,
      dominio: 0,
      dominios: [],
      dominiosSelect: [],
      dimensiones: [],
      dimensionesSelect: [],
      alert: false,
      titulo: "",
      respuesta: "",
      errors: [],
      fin: false,
      items: [],
      itemsSelect: [],
      cantDominios: "valor",
      puntajeCategoria: 0,
      puntajeDominio: 0,
      calificacionCategoria: "",
      calificacionDominio: "",
      normal: false,
      sinDatos: false,
      puntajeFinal: 0,
      calificacionFinal: "",
      completadas: 0,
      empleadosTotales: 0,
      show: false,
      datosCompletos: false,
      auxiliar: [],
      guia2: {
        categorias: [
          {
            id: 3,
            nombreCategoria: "X",
          },
          {
            id: 1,
            nombreCategoria: "y",
          },
          {
            id: 2,
            nombreCategoria: "z",
          },
        ],
        dominios: [
          {
            id: 1,
            nombreDominio: "yx",
            categoriaId: 3,
          },
          {
            id: 1,
            nombreDominio: "yy",
            categoriaId: 1,
          },
        ],
        dimensiones: [
          {
            id: 2,
            nombreDimension: "z",
            dominioId: 1,
            dominio: {
              id: 1,
              nombreDominio: "yy",
              categoriaId: 1,
              categoria: {
                id: 1,
                nombreCategoria: "y",
              },
            },
          },
        ],
        puntajesDominio: [],
        puntajesCategoria: [],
        calificacionesCategoria: [],
        calificacionesFinales: [],
        puntajesFinales: [],
        calificacionesDominio: [],
      },
      caliCategoria: "",
      caliDominio: "",
      red: false,
      yellow: false,
      orange: false,
      green: false,
      blue: false,
    };
  },
  methods: {
    validacion() {
      this.errors = [];
      /*if (this.guia == 2 && this.categoria == 0 && this.dominio == 0) {
        this.sinDatos = true;
        //return (this.normal = false);
        this.peticion();
      }*/
      if (this.guia == 0) {
        this.errors.push("Favor de elegir el tipo de guía");
        this.titulo = "Reporte NOM-035";
        this.alert = true;
      }
      if (this.guia == 2 && this.categoria == 0 && this.dominio == 0) {
        (this.guia2 = {
          categorias: [
            {
              id: 3,
              nombreCategoria: "X",
            },
            {
              id: 1,
              nombreCategoria: "y",
            },
            {
              id: 2,
              nombreCategoria: "z",
            },
          ],
          dominios: [
            {
              id: 1,
              nombreDominio: "yx",
              categoriaId: 3,
            },
            {
              id: 1,
              nombreDominio: "yy",
              categoriaId: 1,
            },
          ],
          dimensiones: [
            {
              id: 2,
              nombreDimension: "z",
              dominioId: 1,
              dominio: {
                id: 1,
                nombreDominio: "yy",
                categoriaId: 1,
                categoria: {
                  id: 1,
                  nombreCategoria: "y",
                },
              },
            },
          ],
          puntajesDominio: [],
          puntajesCategoria: [],
          calificacionesCategoria: [],
          calificacionesFinales: [],
          puntajesFinales: [],
          calificacionesDominio: [],
        }),
          (this.sinDatos = false);
        this.datosCompletos = true;
        this.peticionFor();
      }
      if (this.guia == 2 && this.categoria != 0) {
        this.datosCompletos = false;
        this.normal = true;
        this.peticion();
      }
      if (this.guia == 3 && this.categoria == 0 && this.dominio == 0) {
        this.sinDatos = true;
        this.datosCompletos = false;
        this.peticion();
      }
      if (this.guia == 3 && this.categoria != 0) {
        this.sinDatos = false;
        this.datosCompletos = false;
        this.normal = true;
        this.peticion();
      }
    },
    peticionFor() {
      this.guia2.categorias = this.categorias;
      this.guia2.dominios = this.dominiosSelect;
      this.guia2.dimensiones = this.dimensionesSelect;
      this.guia2.categorias.forEach((value, index) => {
        //this.guia2.dimensiones.forEach((val, index) => {
        this.guia2.dominios.forEach((val, index) => {
          //if (value.id === val.dominio.categoriaId) {
          if (value.id === val.categoriaId) {
            this.show = true;
            axios
              .post(
                Server +
                  "/climaLaboralResultados/consulta-calificacion-preguntas",
                {
                  EmpresaId: parseInt(this.enterprise),
                  NumeroEmpleados: parseInt(this.empleados),
                  EncuestasRequeridas: parseInt(this.encuestas),
                  Guia: parseInt(this.guia),
                  CategoriaId: value.id,
                  DominioId: val.id,
                  AreaId: this.area,
                  SucursalId: this.sucursal,
                },
                {
                  headers: {
                    Authorization: localStorage.token,
                  },
                }
              )
              .then((response) => {
                //console.log(response);
                this.guia2.puntajesCategoria.push({
                  idCategoria: value.id,
                  idDominio: val.id,
                  puntajeCategoria: response.data.puntajeCategoria,
                });
                this.guia2.puntajesDominio.push({
                  idDominio: val.id,
                  idCategoria: value.id,
                  puntajeDominio: response.data.puntajeDominio,
                  calificacionDominio: response.data.calificacionDominio,
                });
                this.guia2.calificacionesCategoria.push({
                  idCategoria: value.id,
                  idDominio: val.id,
                  calificacionCategoria: response.data.calificacionCategoria,
                });
                this.guia2.calificacionesDominio.push({
                  idDominio: val.id,
                  idCategoria: value.id,
                  calificacionDominio: response.data.calificacionDominio,
                });
                this.guia2.calificacionesFinales.push({
                  idCategoria: value.id,
                  idDominio: val.id,
                  calificacionFinal: response.data.calificacionFinal,
                });
                this.guia2.puntajesFinales.push({
                  idCategoria: value.id,
                  idDominio: val.id,
                  puntajeFinal: response.data.puntajeFinal,
                });
                this.show = false;
              })
              .catch((e) => {
                console.log(e);
                this.show = false;
                if (e.response.status == 401) {
                  this.expiration = true;
                }
              });
          } else {
          }
        });
      });
    },
    peticion() {
      /*this.alert = true;
      console.log("Categoria", this.categoria.id);
      console.log("Dominio", this.dominio.id);
      console.log("guia", this.guia);*/
      this.show = true;
      axios
        .post(
          Server + "/climaLaboralResultados/consulta-calificacion-preguntas",
          {
            EmpresaId: parseInt(this.enterprise),
            NumeroEmpleados: parseInt(this.empleados),
            EncuestasRequeridas: parseInt(this.encuestas),
            Guia: parseInt(this.guia),
            CategoriaId: this.categoria.id,
            DominioId: this.dominio.id,
            AreaId: this.area,
            SucursalId: this.sucursal,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.puntajeCategoria = response.data.puntajeCategoria;
          this.puntajeDominio = response.data.puntajeDominio;
          this.calificacionCategoria = response.data.calificacionCategoria;
          this.calificacionDominio = response.data.calificacionDominio;
          (this.calificacionFinal = response.data.calificacionFinal),
            (this.puntajeFinal = response.data.puntajeFinal);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    cambioResultados() {
      this.puntajeCategoria = 0;
      this.puntajeDominio = 0;
      this.calificacionCategoria = 0;
      this.calificacionDominio = 0;
    },
    finalizar() {
      this.fin = false;
      this.alert = false;
      this.respuesta = "";
      this.titulo = "";
      this.enterprise = 0;
      this.guia = 0;
      this.empleados = 0;
      this.encuestas = 0;
      this.categoria = 0;
      this.dominio = 0;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEncuesta() {
      this.cambioGuia();
      this.guia = 0;
      this.area = 0;
      this.sucursal = 0;
      this.empleados = 0;
      this.sinDatos = false;
      this.datosCompletos = false;
      this.normal = false;
      this.show = true;
      axios
        .post(
          Server + "/catalogosNom35/numero-empleados",
          {
            EmpresaId: this.enterprise,
            AreaId: this.area,
            SucursalId: this.sucursal,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.empleadosTotales = response.data.numeroEmpleados;
          this.empleados = response.data.numeroEmpleados;
          this.encuestas = response.data.encuestasProceso;
          this.completadas = response.data.encuestasCompletadas;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
                nombreCorto: value.nombreAreaCorto,
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                nivelJerarquico: value.nivelJerarquico,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.sucursales = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.sucursales = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
        //Todo: Este codigo genera el error en la aplicación  revisar con adolfo
      //var x = (document.getElementById("guia").disabled = false);
      //var y = (document.getElementById("area").disabled = false);
      //var z = (document.getElementById("sucursal").disabled = false);
    },
    obtenerCategoria() {
      this.sinDatos = false;
      this.datosCompletos = false;
      this.normal = false;
      var x = (document.getElementById("categoria").disabled = false);
      if (this.guia == 2) {
        this.cambioGuia();
        this.categoriasGuiaDos();
      }
      if (this.guia == 3) {
        this.cambioGuia();
        this.categoriasGuiaTres();
      }
    },
    cambioGuia() {
      this.categoriasSelect = [];
      this.dominiosSelect = [];
      this.dimensionesSelect = [];
      this.categorias = [];
      this.categoria = 0;
      this.dominios = [];
      this.dominio = 0;
    },
    reset() {
      this.sucursal = 0;
    },
    obtenerDominio() {
      this.sinDatos = false;
      this.datosCompletos = false;
      var x = (document.getElementById("dominio").disabled = false);
      this.normal = true;
      this.dominiosSelect = [];
      this.categoriasSelect = [];
      this.categoriasSelect.push(this.categoria);
      this.dimensionesSelect = [];
      this.dominio = 0;
      this.cambioResultados();
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/dominios/" + this.categoria.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.dominios = response.data;
          this.dominiosSelect = response.data;
          this.dominiosSelect.forEach((element, index) => {
            this.show = true;
            axios
              .get(Server + "/catalogosNom35/dimensiones/" + element.id, {
                headers: {
                  Authorization: localStorage.token,
                },
              })
              .then((response) => {
                /*console.log("dime con cat select" + element.id);
                console.log(response.data);*/
                for (var i = 0; i < response.data.length; i++) {
                  this.dimensionesSelect.push(response.data[i]);
                  //console.log(this.dimensionesSelect);
                }
                this.dimensionesSelect = this.dimensionesSelect;
                this.show = false;
              })
              .catch((e) => {
                console.log(e);
                this.show = false;
                if (e.response.status == 401) {
                  this.expiration = true;
                }
              });
          });
          /*console.log("tam del dimen" + this.dimensionesSelect.length);
          console.log(this.dimensionesSelect);*/
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerDimensiones() {
      /*console.log("las dimensiones");
      console.log(this.dominio);*/
      this.dimensionesSelect = [];
      this.dominiosSelect = [];
      this.cambioResultados();
      this.dominiosSelect.push(this.dominio);
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/dominios/" + this.dominio.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.dominiosSelect.forEach((element, index) => {
            this.show = true;
            axios
              .get(Server + "/catalogosNom35/dimensiones/" + element.id, {
                headers: {
                  Authorization: localStorage.token,
                },
              })
              .then((response) => {
                /*console.log("dime con cat select" + element.id);
                console.log(response.data);*/
                for (var i = 0; i < response.data.length; i++) {
                  this.dimensionesSelect.push(response.data[i]);
                  //console.log(this.dimensionesSelect);
                }
                this.dimensionesSelect = this.dimensionesSelect;
                this.show = false;
              })
              .catch((e) => {
                console.log(e);
                this.show = false;
                if (e.response.status == 401) {
                  this.expiration = true;
                }
              });
          });
          /*console.log("tam del dimen" + this.dimensionesSelect.length);
          console.log(this.dimensionesSelect);*/
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/dimensiones/" + this.dominio, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.dimensionesSelect = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    filtrar(dominio, categoria) {
      if (dominio.categoriaId == categoria.id) {
        return dominio.nombreDominio;
      }
    },
    filtrarDimensiones(dimension, categoria) {
      if (categoria.id == dimension.dominio.categoria.id) {
        return dimension.nombreDimension;
      }
    },
    totalDominios(dominio, categoria) {
      if (dominio.categoriaId == categoria.id) {
        return this.cantDominios;
      }
    },
    punDominio(punDom, categoria) {
      var dominio = {};
      this.guia2.dominios.forEach((value, index) => {
        if (value.categoriaId == categoria.id) {
          dominio = value;
        }
      });
      if (
        punDom.idCategoria ==
        categoria.id /*&&
        punDom.idDominio == dominio.id*/
      ) {
        return punDom.puntajeDominio;
      }
    },
    punCategoria(punCat, categoria) {
      var dominio;
      this.guia2.dominios.forEach((value, index) => {
        if (value.categoriaId == categoria.id) {
          dominio = value;
        }
      });
      if (
        punCat.idCategoria ==
        categoria.id /*&&
        punCat.idDominio == dominio.id*/
      ) {
        return punCat.puntajeCategoria;
      }
    },
    calCategoria(calCat, categoria) {
      var dominio;
      this.guia2.dominios.forEach((value, index) => {
        if (value.categoriaId == categoria.id) {
          dominio = value;
        }
      });
      if (
        calCat.idCategoria == categoria.id &&
        calCat.idDominio == dominio.id
      ) {
        this.caliCategoria = calCat.calificacionCategoria;
        return calCat.calificacionCategoria;
      }
    },
    calDominio(dom, cat) {
      var dominioo;
      this.guia2.dominios.forEach((value, index) => {
        if (value.categoriaId == cat.id) {
          dominioo = value;
        }
      });
      if (dom.idCategoria == cat.id /*&& dom.idDominio == dominioo.id*/) {
        return dom.calificacionDominio;
      }
    },
    categoriasGuiaDos() {
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/categorias", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.categorias = response.data;
          this.categorias.splice(4, 1);
          //console.log("categorias ");
          //console.log(this.categorias);
          this.categoriasSelect = this.categorias;
          //console.log("categorias select");
          //console.log(this.categoriasSelect);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/dominios/", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.dominiosSelect = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/dimensiones", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.dimensionesSelect = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    categoriasGuiaTres() {
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/categorias", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.categorias = response.data;
          this.categoriasSelect = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/dominios/", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.dominiosSelect = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/catalogosNom35/dimensiones", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.dimensionesSelect = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    limpiar() {
      this.sinDatos = false;
      this.datosCompletos = false;
      this.normal = false;
      if (this.categoria == 0) {
        this.enterprise = 0;
        this.area = 0;
        this.sucursal = 0;
        this.empleados = 0;
        this.encuestas = 0;
        this.completadas = 0;
        this.guia = 0;
        this.categoria = 0;
        this.dominio = 0;
        this.areas = [];
        this.sucursales = [];
        this.categorias = [];
        this.dominios = [];
        var x = (document.getElementById("guia").disabled = true);
        var y = (document.getElementById("area").disabled = true);
        var z = (document.getElementById("sucursal").disabled = true);
        var a = (document.getElementById("categoria").disabled = true);
        var b = (document.getElementById("dominio").disabled = true);
      }
      if (this.categoria != 0) {
        this.validacion();
      }
    },
    actualizarCantidadEmpleados() {
      this.show = true;
      axios
        .post(
          Server + "/catalogosNom35/numero-empleados",
          {
            EmpresaId: this.enterprise,
            AreaId: this.area,
            SucursalId: this.sucursal,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.empleados = response.data.numeroEmpleados;
          this.encuestas = response.data.encuestasProceso;
          this.completadas = response.data.encuestasCompletadas;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  computed: {
    color() {
      return {
        "red darken-1": this.calificacionDominio == "Muy alto",
        "orange darken-1": this.calificacionDominio == "Alto",
        "yellow lighten-3": this.calificacionDominio == "Medio",
        "green accent-3": this.calificacionDominio == "Bajo",
        blue: this.calificacionDominio === "Nulo o despreciable",
      };
    },
    color2() {
      return {
        "red darken-1": this.calificacionCategoria == "Muy alto",
        "orange darken-1": this.calificacionCategoria == "Alto",
        "yellow lighten-3": this.calificacionCategoria == "Medio",
        "green accent-3": this.calificacionCategoria == "Bajo",
        blue: this.calificacionCategoria === "Nulo o despreciable",
      };
    },
    color3() {
      return {
        "red darken-1": this.calificacionFinal == "Muy alto",
        "orange darken-1": this.calificacionFinal == "Alto",
        "yellow lighten-3": this.calificacionFinal == "Medio",
        "green accent-3": this.calificacionFinal == "Bajo",
        blue: this.calificacionFinal === "Nulo o despreciable",
      };
    },
    calificacionCategoriaColor() {
      return {
        "red darken-1": this.caliCategoria === "Muy alto",
        "orange darken-1": this.caliCategoria == "Alto",
        "yellow lighten-3": this.caliCategoria == "Medio",
        "green accent-3": this.caliCategoria == "Bajo",
        blue: this.caliCategoria === "Nulo o despreciable",
      };
    },
    calificacionDominioColor(dom) {
      console.log(dom);
      console.log("dom que llega");
      return {
        "red darken-1": this.red === "Muy alto",
        "orange darken-1": this.orange === "Alto",
        "yellow lighten-3": this.yellow === "Medio",
        "green accent-3": this.green === "Bajo",
        blue: this.blue === "Nulo o despreciable",
      };
    },
  },
  created() {
    this.listar();
    this.obtenerEncuesta();
  },
};
</script>